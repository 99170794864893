"use client";
import { useActiveAccount,} from "thirdweb/react";
import { IAppContext,} from "@/types";
import React from "react";
import {  fetchAssistants, getUser,  } from "@/lib/query-funtions";
interface Props {
    children: React.ReactNode;
}
import { useConfigStore } from "@/hooks/store";
import { User } from "@prisma/client";
export const AppContext = React.createContext<IAppContext>({} as IAppContext);

const AppContexProvider = ({ children }: Props) => {
    const activeAccount = useActiveAccount()
    const [activeUser, setActiveUser] = React.useState<User>();
    const getUserData = async () => {
        try {
            if (activeAccount?.address && activeAccount) {
                const dbUser = await getUser(activeAccount.address)
                setActiveUser(dbUser)
            }
        } catch (error) {
            console.log(error)
        }
    }

    React.useEffect(() => {
        getUserData()
    }, [activeAccount]);

    const pullRemoteState = async () => {

    }
    return (
        <AppContext.Provider value={{ user: activeUser, setUSer: setActiveUser, pullRemoteState }}>
            {children}
        </AppContext.Provider>
    )
}

export default AppContexProvider