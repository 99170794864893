export const prompts: string[] = [
    // Exploring Blockchain Data
    "Show me the transaction history of a specific Bitcoin address.",
    "What are the recent transactions on the Ethereum network?",
    "Find the largest Bitcoin transactions in the past 24 hours.",
    "What are the most active wallets on the Ethereum blockchain?",
  
    // Analyzing Blockchain Metrics
    "What is the current Bitcoin hashrate?",
    "Show me the latest Ethereum gas prices.",
    "How many new Bitcoin blocks were mined today?",
    "What is the current market capitalization of Bitcoin?",
  
    // Cryptocurrency Information
    "What is the current price of Bitcoin?",
    "Provide the historical price data for Ethereum over the past month.",
    "Compare the market caps of Bitcoin and Ethereum.",
    "Show me the top 10 cryptocurrencies by market cap.",
  
    // Blockchain Technology Insights
    "Explain how a smart contract works on the Ethereum blockchain.",
    "What are the benefits of using blockchain for supply chain management?",
    "Describe the differences between proof of work and proof of stake.",
  
    // Market Trends and Analysis
    "What are the latest trends in the cryptocurrency market?",
    "Analyze the price movements of Bitcoin over the past week.",
    "What are experts saying about the future of blockchain technology?",
  
    // Security and Best Practices
    "What are the best practices for keeping my cryptocurrency secure?",
    "How can I protect my blockchain transactions from being hacked?",
    "What are the common scams in the cryptocurrency world and how to avoid them?",
  
    // Blockchain Projects and Developments
    "Tell me about the latest developments in the Ethereum 2.0 upgrade.",
    "What are some promising blockchain projects to watch?",
    "Explain the concept of DeFi (Decentralized Finance).",
  
    // Regulatory and Legal Information
    "What are the current regulations around cryptocurrency in the US?",
    "How do different countries regulate Bitcoin?",
    "What are the legal implications of using blockchain technology?"
  ];
  