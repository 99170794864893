"use client";
import {
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'
import { ThirdwebProvider } from "thirdweb/react";
import React from "react";

interface Props {
    children: React.ReactNode;
}
const queryClient = new QueryClient()
export const Provider = ({ children }: Props) => {
    return (
        <QueryClientProvider client={queryClient}>

            <ThirdwebProvider

            // supportedWallets={[
            //     metamaskWallet({
            //         recommended: true,
            //     }),
            //     coinbaseWallet(),
            //     walletConnect(),
            //     trustWallet(),

            // ]}
            // autoConnect

            // supportedChains={[Ethereum]}
            // activeChain={Ethereum}
            // clientId={process.env.NEXT_PUBLIC_CLIENT_ID}
            // autoConnectTimeout={15000 }
            // autoSwitch
            // authConfig={{
            //     // Set this to your domain to prevent phishing attacks
            //     domain: "https://data.dev.gemach.io:8080",
            //     // The URL of your Auth API
            //     authUrl: "/api/auth",

            // }}
            >
                {children}
            </ThirdwebProvider>
        </QueryClientProvider>
    );
};