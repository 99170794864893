import axios from "axios"
import { Agent, Message, Session, Skill, Task, User, Workflow } from "@prisma/client"


// Create an axios instance
export const getUser = async (address: string) => {

  try {
    const { data } = await axios.get(`/api/users/${address}`)
    return data as User
  } catch (error: any) {
    console.log(error)
    return undefined
  }
}

export const fetchSkills = async (): Promise<Skill[]> => {
  try {

    const { data } = await axios.get(`/api/skills`,)
    return data as Skill[]
  } catch (error) {
    return []
  }
}

export const fetchAssistants = async (): Promise<Agent[]> => {
  try {
    const { data } = await axios.get(`/api/agents`)
    return data as Agent[]
  } catch (error) {
    return []
  }
}



export const fetchWorkflows = async (): Promise<Workflow[]> => {
  try {
    const { data } = await axios.get(`/api/workflows`)
    return data as Workflow[]
  } catch (error) {
    return []
  }
}

export const fetchTasks = async (): Promise<Task[]> => {
  try {
    const { data } = await axios.get(`/api/tasks`)
    return data as Task[]
  } catch (error) {
    return []
  }
}


export const fetchSessions = async (): Promise<Session[] | null> => {
  try {
    const { data } = await axios.get(`/api/sessions`)
    return data as Session[]
  } catch (error) {
    return null
  }
}

export const fetchMessages = async (sessionId: string): Promise<Message[] | null> => {
  try {
    const { data } = await axios.get(`/api/messages/${sessionId}`)
    return data as Message[]
  } catch (error) {
    return null
  }
}